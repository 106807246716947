<template>
  <div class="content_vox">
    <div class="content">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="first">
          <el-form
            label-width="120px"
            :model="form"
            ref="chainRef"
            :rules="chainRule"
          >
            <div class="mainTop">
              <!-- <el-form-item label="工艺名称：" style="width: 640px" prop="">
                <div class="circle_flex" v-for="(o, i) in qianList" :key="i">
                  <el-input v-model="o.name"></el-input>
                  <i
                    @click="addpush(0)"
                    class="el-icon-circle-plus-outline"
                  ></i>
                  <i
                    v-if="i != 0"
                    class="el-icon-remove-outline"
                    @click="remove(0, i)"
                  ></i>
                </div>
              </el-form-item> -->
              <el-form-item label="工艺名称：" style="width: 640px" prop="">
                <el-input
                  v-model="form.processName"
                  placeholder="请输入工艺名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="描述：">
                <el-input
                  v-model="form.processDescribe"
                  style="width: 56%"
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                ></el-input>
                <!-- <Editorbar
                  v-model="form.sceneDescribe"
                  :isClear="isClear"
                  style="width: 50%"
                /> -->
              </el-form-item>

              <el-form-item label="上级场景：" style="width: 470px" prop="">
                <el-select
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="remoteMethod"
                  v-model="form.superiorBehaviorId"
                >
                  <el-option
                    v-for="item in sceneList"
                    :key="item.id"
                    :label="item.sceneName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属行业：" style="width: 470px" prop="">
                <!-- <el-select
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="remoteMethods"
                  v-model="form.label"
                >
                  <el-option
                    v-for="item in sceneArr"
                    :key="item.id"
                    :label="item.labelName"
                    :value="item.labelName"
                  >
                  </el-option>
                </el-select> -->
                <el-cascader
                  v-model="list"
                  :options="industryThreeOptions"
                  :props="industryThreeProps"
                  ref="industryThree"
                  @change="handleContentChaChange(list)"
                  clearable
                  collapse-tags
                ></el-cascader>
              </el-form-item>
              <el-form-item label="所属产业链：" style="width: 470px" prop="">
                <el-select
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  v-model="form.industrialChainId"
                >
                  <el-option
                    v-for="item in sceneArrs"
                    :key="item.id"
                    :label="item.supplyChainName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="关联产品：" style="width: 470px" prop="">
                <el-select
                  filterable
                  remote
                  multiple
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="remoteMethodsss"
                  @change="$forceUpdate()"
                  v-model="form.productCodeList"
                >
                  <el-option
                    v-for="item in sceneArr1"
                    :key="item.productCode"
                    :label="item.productName"
                    :value="item.productCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="关联企业："
                style="width: 470px"
                prop="companyId"
              >
                <el-select
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="remoteMethodssss"
                  v-model="form.companyId"
                >
                  <el-option
                    v-for="item in sceneArr2"
                    :key="item.id"
                    :label="item.companyFullName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="传统场景知识">
                <el-radio-group v-model="form.traditionalScene">
                  <el-radio :label="true" :value="true">是</el-radio>
                  <el-radio :label="false" :value="false">否</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="工艺工序：" prop="">
                <div
                  class="flex_box"
                  v-for="(item1, index1) in form.productionProcessList"
                  :key="index1"
                  style="padding: 10px 0 0 0px"
                >
                  <div class="general_inputs">
                    <el-form-item label="一级：" label-width="100px">
                      <div class="input_flex">
                        <el-input
                          v-model="item1.productionName"
                          size="small"
                        ></el-input>

                        <el-dropdown>
                          <span
                            class="el-dropdown-link"
                            style="cursor: pointer"
                          >
                            <i class="el-icon-circle-plus-outline"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                              v-for="(el, i) in levelabelone"
                              :key="i"
                              @click.native="addlabel1(index1)"
                              >{{ el.val }}</el-dropdown-item
                            >
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                      <div class="input_flex left_miao">
                        <div class="miaoshu">描述：</div>
                        <el-input
                          v-model="item1.productionDescribe"
                          size="small"
                        ></el-input>
                      </div>
                    </el-form-item>

                    <div
                      v-for="(item2, index2) in item1.children"
                      :key="index2"
                      style="padding-left: 100px"
                    >
                      <el-form-item label="二级：" label-width="80px">
                        <div class="input_flex">
                          <el-input
                            v-model="item2.productionName"
                            size="small"
                          ></el-input>

                          <el-dropdown>
                            <span
                              class="el-dropdown-link"
                              style="cursor: pointer"
                            >
                              <i class="el-icon-circle-plus-outline"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item
                                v-for="(el, i) in levelabeltwo"
                                :key="i"
                                @click.native="
                                  addlabel2(index1, index2, el.num, i)
                                "
                                >{{ el.val }}</el-dropdown-item
                              >
                            </el-dropdown-menu>
                          </el-dropdown>
                          <span v-if="index2 >= 0">
                            <i
                              @click="delet2(index1, index2)"
                              class="el-icon-remove-outline"
                            ></i>
                          </span>
                          <!-- <span v-if="personData != null && item2.id == null">
                            <i
                              @click="delet2(index1, index2)"
                              class="el-icon-remove-outline"
                            ></i>
                          </span> -->
                          <!-- <span
                            v-if="personData"
                            class="el_inco"
                            @click="addLabelList2(item2, index1, index2)"
                          >
                            <i class="el-icon-arrow-up"></i>
                          </span> -->
                        </div>
                        <div class="input_flex left_miao">
                          <div class="miaoshu">描述：</div>
                          <el-input
                            v-model="item2.productionDescribe"
                            size="small"
                          ></el-input>
                        </div>
                      </el-form-item>

                      <div
                        v-for="(item3, index3) in item2.children"
                        :key="index3"
                        style="padding-left: 100px"
                      >
                        <el-form-item label="三级：" label-width="80px">
                          <div class="input_flex">
                            <el-input
                              v-model="item3.productionName"
                              size="small"
                            ></el-input>

                            <el-dropdown>
                              <span
                                class="el-dropdown-link"
                                style="cursor: pointer"
                              >
                                <i class="el-icon-circle-plus-outline"></i>
                              </span>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                  v-for="(el, i) in levelabelthree"
                                  :key="i"
                                  @click.native="
                                    addlabel3(index1, index2, index3, el.num, i)
                                  "
                                  >{{ el.val }}</el-dropdown-item
                                >
                                <!-- <el-dropdown-item @click.native="addlabel2(index1, index2, 'all')"
                                  >添加所有下级</el-dropdown-item
                                > -->
                              </el-dropdown-menu>
                            </el-dropdown>
                            <span v-if="!personData">
                              <i
                                @click="delet3(index1, index2, index3)"
                                class="el-icon-remove-outline"
                              ></i>
                            </span>
                            <span v-if="index3 >= 0">
                              <i
                                @click="delet3(index1, index2, index3)"
                                class="el-icon-remove-outline"
                              ></i>
                            </span>
                            <!-- <span
                              v-if="personData"
                              class="el_inco"
                              @click="
                                addLabelList3(item3, index1, index2, index3)
                              "
                            >
                              <i class="el-icon-arrow-up"></i>
                            </span> -->
                          </div>
                          <div class="input_flex left_miao">
                            <div class="miaoshu">描述：</div>
                            <el-input
                              v-model="item3.productionDescribe"
                              size="small"
                            ></el-input>
                          </div>
                        </el-form-item>

                        <div
                          v-for="(item4, index4) in item3.children"
                          :key="index4"
                          style="padding-left: 100px"
                        >
                          <el-form-item label="四级：" label-width="80px">
                            <div class="input_flex">
                              <el-input
                                v-model="item4.productionName"
                                size="small"
                              ></el-input>

                              <el-dropdown>
                                <span
                                  class="el-dropdown-link"
                                  style="cursor: pointer"
                                >
                                  <i class="el-icon-circle-plus-outline"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                  <el-dropdown-item
                                    v-for="(el, i) in levelabelfour"
                                    :key="i"
                                    @click.native="
                                      addlabel4(
                                        index1,
                                        index2,
                                        index3,
                                        index4,
                                        el.num,
                                        i
                                      )
                                    "
                                    >{{ el.val }}</el-dropdown-item
                                  >
                                  <!-- <el-dropdown-item
                                    @click.native="addFour(index1, index2, index3, 'all')"
                                    >添加所有下级</el-dropdown-item
                                  > -->
                                </el-dropdown-menu>
                              </el-dropdown>
                              <span v-if="!personData">
                                <i
                                  @click="
                                    delet4(index1, index2, index3, index4)
                                  "
                                  class="el-icon-remove-outline"
                                ></i>
                              </span>
                              <span
                                v-if="personData != null && item4.id == null"
                              >
                                <i
                                  @click="
                                    delet4(index1, index2, index3, index4)
                                  "
                                  class="el-icon-remove-outline"
                                ></i>
                              </span>
                              <!-- <span
                                v-if="personData"
                                class="el_inco"
                                @click="
                                  addLabelList4(
                                    item4,
                                    index1,
                                    index2,
                                    index3,
                                    index4
                                  )
                                "
                              >
                                <i class="el-icon-arrow-up"></i>
                              </span> -->
                            </div>
                            <div class="input_flex left_miao">
                              <div class="miaoshu">描述：</div>
                              <el-input
                                v-model="item4.productionDescribe"
                                size="small"
                              ></el-input>
                            </div>
                          </el-form-item>

                          <div
                            v-for="(item5, index5) in item4.children"
                            :key="index5"
                            style="padding-left: 100px"
                          >
                            <el-form-item label="五级：" label-width="80px">
                              <div class="input_flex">
                                <el-input
                                  v-model="item5.productionName"
                                  size="small"
                                ></el-input>

                                <el-dropdown>
                                  <span
                                    class="el-dropdown-link"
                                    style="cursor: pointer"
                                  >
                                    <i class="el-icon-circle-plus-outline"></i>
                                  </span>
                                  <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item
                                      v-for="(el, i) in levelabelfive"
                                      :key="i"
                                      @click.native="
                                        addlabel5(
                                          index1,
                                          index2,
                                          index3,
                                          index4,
                                          index5,
                                          el.num,
                                          i
                                        )
                                      "
                                      >{{ el.val }}</el-dropdown-item
                                    >
                                  </el-dropdown-menu>
                                </el-dropdown>
                                <span v-if="!personData">
                                  <i
                                    @click="
                                      delet5(
                                        index1,
                                        index2,
                                        index3,
                                        index4,
                                        index5
                                      )
                                    "
                                    class="el-icon-remove-outline"
                                  ></i>
                                </span>
                                <span
                                  v-if="personData != null && item5.id == null"
                                >
                                  <i
                                    @click="
                                      delet5(
                                        index1,
                                        index2,
                                        index3,
                                        index4,
                                        index5
                                      )
                                    "
                                    class="el-icon-remove-outline"
                                  ></i>
                                </span>
                                <!-- <span
                                  v-if="personData"
                                  class="el_inco"
                                  @click="
                                    addLabelList5(
                                      item5,
                                      index1,
                                      index2,
                                      index3,
                                      index4,
                                      index5
                                    )
                                  "
                                >
                                  <i class="el-icon-arrow-up"></i>
                                </span> -->
                              </div>
                              <div class="input_flex left_miao">
                                <div class="miaoshu">描述：</div>
                                <el-input
                                  v-model="item5.productionDescribe"
                                  size="small"
                                ></el-input>
                              </div>
                            </el-form-item>
                            <div
                              v-for="(item6, index6) in item5.children"
                              :key="index6"
                              style="padding-left: 100px"
                            >
                              <el-form-item label="六级：" label-width="80px">
                                <div class="input_flex">
                                  <el-input
                                    v-model="item6.productionName"
                                    size="small"
                                  ></el-input>

                                  <el-dropdown>
                                    <span
                                      class="el-dropdown-link"
                                      style="cursor: pointer"
                                    >
                                      <i
                                        class="el-icon-circle-plus-outline"
                                      ></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                      <el-dropdown-item
                                        v-for="(el, i) in levelabelsix"
                                        :key="i"
                                        @click.native="
                                          addlabel6(
                                            index1,
                                            index2,
                                            index3,
                                            index4,
                                            index5,
                                            index6,
                                            el.num,
                                            i
                                          )
                                        "
                                        >{{ el.val }}</el-dropdown-item
                                      >
                                    </el-dropdown-menu>
                                  </el-dropdown>
                                  <span v-if="!personData">
                                    <i
                                      @click="
                                        delet6(
                                          index1,
                                          index2,
                                          index3,
                                          index4,
                                          index5,
                                          index6
                                        )
                                      "
                                      class="el-icon-remove-outline"
                                    ></i>
                                  </span>
                                  <span
                                    v-if="
                                      personData != null && item6.id == null
                                    "
                                  >
                                    <i
                                      @click="
                                        delet6(
                                          index1,
                                          index2,
                                          index3,
                                          index4,
                                          index5,
                                          index6
                                        )
                                      "
                                      class="el-icon-remove-outline"
                                    ></i>
                                  </span>
                                  <!-- <span
                                    v-if="personData"
                                    class="el_inco"
                                    @click="
                                      addLabelList6(
                                        item6,
                                        index1,
                                        index2,
                                        index3,
                                        index4,
                                        index5,
                                        index6
                                      )
                                    "
                                  >
                                    <i class="el-icon-arrow-up"></i>
                                  </span> -->
                                </div>
                                <div class="input_flex left_miao">
                                  <div class="miaoshu">描述：</div>
                                  <el-input
                                    v-model="item6.productionDescribe"
                                    size="small"
                                  ></el-input>
                                </div>
                              </el-form-item>
                              <div
                                v-for="(item7, index7) in item6.children"
                                :key="index7"
                                style="padding-left: 100px"
                              >
                                <el-form-item label="七级：" label-width="80px">
                                  <div class="input_flex">
                                    <el-input
                                      v-model="item7.productionName"
                                      size="small"
                                    ></el-input>

                                    <el-dropdown>
                                      <span
                                        class="el-dropdown-link"
                                        style="cursor: pointer"
                                      >
                                        <i
                                          class="el-icon-circle-plus-outline"
                                        ></i>
                                      </span>
                                      <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item
                                          v-for="(el, i) in levelabelseven"
                                          :key="i"
                                          @click.native="
                                            addlabel7(
                                              index1,
                                              index2,
                                              index3,
                                              index4,
                                              index5,
                                              index6,
                                              index7,
                                              el.num,
                                              i
                                            )
                                          "
                                          >{{ el.val }}</el-dropdown-item
                                        >
                                      </el-dropdown-menu>
                                    </el-dropdown>
                                    <span v-if="!personData">
                                      <i
                                        @click="
                                          delet7(
                                            index1,
                                            index2,
                                            index3,
                                            index4,
                                            index5,
                                            index6,
                                            index7
                                          )
                                        "
                                        class="el-icon-remove-outline"
                                      ></i>
                                    </span>
                                    <span
                                      v-if="
                                        personData != null && item7.id == null
                                      "
                                    >
                                      <i
                                        @click="
                                          delet7(
                                            index1,
                                            index2,
                                            index3,
                                            index4,
                                            index5,
                                            index6,
                                            index7
                                          )
                                        "
                                        class="el-icon-remove-outline"
                                      ></i>
                                    </span>
                                    <!-- <span
                                      v-if="personData"
                                      class="el_inco"
                                      @click="
                                        addLabelList7(
                                          item7,
                                          index1,
                                          index2,
                                          index3,
                                          index4,
                                          index5,
                                          index6,
                                          index7
                                        )
                                      "
                                    >
                                      <i class="el-icon-arrow-up"></i>
                                    </span> -->
                                  </div>
                                  <div class="input_flex left_miao">
                                    <div class="miaoshu">描述：</div>
                                    <el-input
                                      v-model="item7.productionDescribe"
                                      size="small"
                                    ></el-input>
                                  </div>
                                </el-form-item>
                                <div
                                  v-for="(item8, index8) in item7.children"
                                  :key="index8"
                                  style="padding-left: 100px"
                                >
                                  <el-form-item
                                    label="八级："
                                    label-width="80px"
                                  >
                                    <div class="input_flex">
                                      <el-input
                                        v-model="item8.productionName"
                                        size="small"
                                      ></el-input>

                                      <el-dropdown>
                                        <span
                                          class="el-dropdown-link"
                                          style="cursor: pointer"
                                        >
                                          <i
                                            class="el-icon-circle-plus-outline"
                                          ></i>
                                        </span>
                                        <el-dropdown-menu slot="dropdown">
                                          <el-dropdown-item
                                            v-for="(el, i) in levelabeleight"
                                            :key="i"
                                            @click.native="
                                              addlabel8(item7, item8, el.num, i)
                                            "
                                            >{{ el.val }}</el-dropdown-item
                                          >
                                        </el-dropdown-menu>
                                      </el-dropdown>
                                      <span v-if="!personData">
                                        <i
                                          @click="delet8(item7, item8, index8)"
                                          class="el-icon-remove-outline"
                                        ></i>
                                      </span>
                                      <span
                                        v-if="
                                          personData != null && item8.id == null
                                        "
                                      >
                                        <i
                                          @click="delet8(item7, item8, index8)"
                                          class="el-icon-remove-outline"
                                        ></i>
                                      </span>
                                      <!-- <span
                                        v-if="personData"
                                        class="el_inco"
                                        @click="
                                          addLabelList8(
                                            item8,
                                            index1,
                                            index2,
                                            index3,
                                            index4,
                                            index5,
                                            index6,
                                            index7,
                                            index8
                                          )
                                        "
                                      >
                                        <i class="el-icon-arrow-up"></i>
                                      </span> -->
                                    </div>
                                    <div class="input_flex left_miao">
                                      <div class="miaoshu">描述：</div>
                                      <el-input
                                        v-model="item8.productionDescribe"
                                        size="small"
                                      ></el-input>
                                    </div>
                                  </el-form-item>
                                  <div
                                    v-for="(item9, index9) in item8.children"
                                    :key="index9"
                                    style="padding-left: 100px"
                                  >
                                    <el-form-item
                                      label="九级："
                                      label-width="80px"
                                    >
                                      <div class="input_flex">
                                        <el-input
                                          v-model="item9.productionName"
                                          size="small"
                                        ></el-input>

                                        <el-dropdown>
                                          <span
                                            class="el-dropdown-link"
                                            style="cursor: pointer"
                                          >
                                            <i
                                              class="el-icon-circle-plus-outline"
                                            ></i>
                                          </span>
                                          <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item
                                              v-for="(el, i) in levelabelnine"
                                              :key="i"
                                              @click.native="
                                                addlabel9(
                                                  index1,
                                                  index2,
                                                  index3,
                                                  index4,
                                                  index5,
                                                  index6,
                                                  index7,
                                                  index8,
                                                  index9,
                                                  el.num,
                                                  i
                                                )
                                              "
                                              >{{ el.val }}</el-dropdown-item
                                            >
                                          </el-dropdown-menu>
                                        </el-dropdown>
                                        <span v-if="!personData">
                                          <i
                                            @click="
                                              delet9(
                                                index1,
                                                index2,
                                                index3,
                                                index4,
                                                index5,
                                                index6,
                                                index7,
                                                index8,
                                                index9
                                              )
                                            "
                                            class="el-icon-remove-outline"
                                          ></i>
                                        </span>
                                        <span
                                          v-if="
                                            personData != null &&
                                            item9.id == null
                                          "
                                        >
                                          <i
                                            @click="
                                              delet9(
                                                index1,
                                                index2,
                                                index3,
                                                index4,
                                                index5,
                                                index6,
                                                index7,
                                                index8,
                                                index9
                                              )
                                            "
                                            class="el-icon-remove-outline"
                                          ></i>
                                        </span>
                                        <!-- <span
                                          v-if="personData"
                                          class="el_inco"
                                          @click="
                                            addLabelList9(
                                              item9,
                                              index1,
                                              index2,
                                              index3,
                                              index4,
                                              index5,
                                              index6,
                                              index7,
                                              index8,
                                              index9
                                            )
                                          "
                                        >
                                          <i class="el-icon-arrow-up"></i>
                                        </span> -->
                                      </div>
                                    </el-form-item>
                                    <div
                                      v-for="(
                                        item10, index10
                                      ) in item9.children"
                                      :key="index10"
                                      style="padding-left: 100px"
                                    >
                                      <el-form-item
                                        label="十级："
                                        label-width="80px"
                                      >
                                        <div class="input_flex">
                                          <el-input
                                            v-model="item10.productionName"
                                            size="small"
                                          ></el-input>

                                          <i
                                            @click="
                                              addlabel10(
                                                index1,
                                                index2,
                                                index3,
                                                index4,
                                                index5,
                                                index6,
                                                index7,
                                                index8,
                                                index9
                                              )
                                            "
                                            class="el-icon-circle-plus-outline"
                                          ></i>
                                          <span v-if="!personData">
                                            <i
                                              @click="
                                                delet10(
                                                  index1,
                                                  index2,
                                                  index3,
                                                  index4,
                                                  index5,
                                                  index6,
                                                  index7,
                                                  index8,
                                                  index9,
                                                  index10
                                                )
                                              "
                                              class="el-icon-remove-outline"
                                            ></i>
                                          </span>
                                        </div>
                                        <div class="input_flex left_miao">
                                          <div class="miaoshu">描述：</div>
                                          <el-input
                                            v-model="item9.productionDescribe"
                                            size="small"
                                          ></el-input>
                                        </div>
                                      </el-form-item>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div>
                  <span @click="addlabel" class="el-dropdown-link" style="cursor: pointer">
                    <i class="el-icon-circle-plus-outline"></i>
                  </span>
                  <span v-if="index1 > 0" @click="delet1(index1)">
                    <i class="el-icon-remove-outline"></i>
                  </span>
                </div> -->
                </div>
              </el-form-item>
            </div>
          </el-form>
          <!-- <div class="ess_people" v-if="personData">
            <div class="ess_people_list">创建人：{{ form.createName }}</div>
            <div class="ess_people_list">修改人：{{ form.updateName }}</div>
            <div class="ess_people_list">创建时间：{{ form.createTimes }}</div>
            <div class="ess_people_list">修改时间：{{ form.updateTimes }}</div>
          </div> -->

          <div class="bottom">
            <el-button type="primary" @click="saveItem">保存</el-button>
            <el-button @click="cancelItem">取消</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
    
    <script>
import Editorbar from "@/components/Editorbar";
import {
  updateScenarioBasic,
  insertGenerationProcess,
  getIndustryLabelList,
} from "@/api/Sceneknowledge.js";
import {
  selectAssociatedScenarios,
  updateGenerationProcess,
  selectAssociated,
  getEmpoweringLabels,
  getAllIndustrialChain,
  getProductByName,
  getZcByCompanyName,
  selectById,
} from "@/api/standardKnowledge.js";
import { getIndustryList } from "@/api/common.js";
export default {
  name: "addbehavioral",
  components: {
    Editorbar,
  },
  watch: {},

  computed: {
    personData() {
      return Number(this.$route.query.id);
    },
  },
  data() {
    return {
      list: [], //所
      industryThreeProps: {
        multiple: true,
        value: "id",
        label: "industryName",
        children: "childNode",
      },

      qianList: [
        {
          name: "",
        },
      ],
      levelabelone: [
        {
          val: "添加二级",
          num: "two",
        },
      ],
      levelabeltwo: [
        {
          val: "添加二级",
          num: "two",
        },
        {
          val: "添加三级",
          num: "three",
        },
      ],
      levelabelthree: [
        {
          val: "添加三级",
          num: "three",
        },
        {
          val: "添加四级",
          num: "four",
        },
      ],
      levelabelfour: [
        {
          val: "添加四级",
          num: "four",
        },
        {
          val: "添加五级",
          num: "five",
        },
      ],
      levelabelfive: [
        {
          val: "添加五级",
          num: "five",
        },

        {
          val: "添加六级",
          num: "six",
        },
      ],
      levelabelsix: [
        {
          val: "添加六级",
          num: "six",
        },
        {
          val: "添加七级",
          num: "seven",
        },
      ],
      levelabelseven: [
        {
          val: "添加七级",
          num: "seven",
        },
        {
          val: "添加八级",
          num: "eight",
        },
      ],
      levelabeleight: [
        {
          val: "添加八级",
          num: "eight",
        },
        {
          val: "添加九级",
          num: "nine",
        },
      ],
      levelabelnine: [
        {
          val: "添加九级",
          num: "nine",
        },
        {
          val: "添加,十级",
          num: "ten",
        },
      ],

      activeName: "first",
      sceneList: [], //
      sceneArr: [],
      sceneArrs: [],
      sceneArr1: [],
      sceneArr2: [],
      isClear: null,
      isShow: false,
      isShowManufacturingCategory: false,
      options: [],
      form: {
        productCodeList: [],
        companyId: "",
        id: 0,
        industrialChainId: "",
        label: "",
        labelList: [],
        processDescribe: "",
        processName: "",
        productCode: "",
        productionProcessList: [
          {
            children: [
              {
                children: [{}],
                generationProcessId: 0,
                id: 0,
                parentId: 0,
                productionDescribe: "",
                productionName: "",
              },
            ],
            generationProcessId: 0,
            id: 0,
            parentId: 0,
            productionDescribe: "",
            productionName: "",
          },
        ],
        superiorBehaviorId: "",
        traditionalScene: true,
      },
      industryThreeOptions: [],
      //企业行为

      chainRule: {
        sceneName: [
          { required: true, message: "请填写场景名称", trigger: "blur" },
        ],
        sceneProperties: [
          { required: true, message: "请选择场景属性", trigger: "blur" },
        ],
        companyId: [{ required: true, message: "请选择企业", trigger: "blur" }],
      },
    };
  },
  created() {
    if (this.personData) {
      this.search();
    } else {
      this.remoteMethods(""); //上级行为
      this.remoteMethod(""); //行业
      this.remoteMethodss(); //产业链
      this.remoteMethodsss(""); //产品
      // this.remoteMethodssss('')//企业
    }
    this.getIndustryThree();
  },
  methods: {
    async getIndustryThree() {
      const res = await getIndustryLabelList();
      if (res.code == 200) {
        this.industryThreeOptions = res.data;
      }
    },
    handleContentChaChange(list) {
      this.form.labelList = list.map((el, index) => {
        return el.join(",");
      });
    },
    //一级下标签添加
    addlabel1(index1) {
      if (!this.form.productionProcessList[index1].children) {
        this.form.productionProcessList[index1].children = [];
      }

      this.form.productionProcessList[index1].children.push({
        associateId: "", //标签之间的关联
        id: null,
        productionName: null,
        productionDescribe: "",
        children: [],
      });
      if (this.form.groupLabelLevel == 1) {
        this.form.groupLabelLevel = this.form.groupLabelLevel + 1;
      }
    },
    addlabel() {
      this.form.productionProcessList.push({
        associateId: "", //标签之间的关联
        id: null,
        productionName: null,
        productionDescribe: "",
        children: [],
      });
    },
    //删除一级下标签
    delet1(index1) {
      this.form.productionProcessList.splice(index1, 1);
    },
    //二级下标签新加
    addlabel2(index1, index2, msg, i) {
      this.Level = 1;

      if (msg != "two") {
        if (this.form.groupLabelLevel == 2) {
          this.form.groupLabelLevel = this.form.groupLabelLevel + 1;
        }
        this.levelchanges(
          this.form.productionProcessList[index1].children[index2],
          i
        );
      } else {
        this.form.productionProcessList[index1].children.push({
          associateId: "", //标签之间的关联
          id: null,
          productionName: null,
          productionDescribe: "",
          children: [],
        });
      }
    },
    //删除二级下标签
    delet2(index1, index2) {
      let i = this.form.productionProcessList[index1].children.length;
      if (i == 1) {
        this.form.groupLabelLevel = this.form.groupLabelLevel - 1;
      }
      this.form.productionProcessList[index1].children.splice(index2, 1);
    },
    //三级下标签新加
    addlabel3(index1, index2, index3, msg, i) {
      this.Level = 1;

      if (msg != "three") {
        if (this.form.groupLabelLevel == 3) {
          this.form.groupLabelLevel = this.form.groupLabelLevel + 1;
        }
        this.levelchanges(
          this.form.productionProcessList[index1].children[index2].children[
            index3
          ],
          i
        );
      } else {
        this.form.productionProcessList[index1].children[index2].children.push({
          associateId: "", //标签之间的关联
          id: null,
          productionName: null,
          productionDescribe: "",
          children: [],
        });
      }
    },
    //删除3级下标签
    delet3(index1, index2, index3) {
      let i =
        this.form.productionProcessList[index1].children[index2].children
          .length;
      if (i == 1) {
        this.form.groupLabelLevel = this.form.groupLabelLevel - 1;
      }
      this.form.productionProcessList[index1].children[index2].children.splice(
        index3,
        1
      );
    },
    //四级下标签新加
    addlabel4(index1, index2, index3, index4, msg, i) {
      this.Level = 1;

      if (msg != "four") {
        if (this.form.groupLabelLevel == 4) {
          this.form.groupLabelLevel = this.form.groupLabelLevel + 1;
        }
        this.levelchanges(
          this.form.productionProcessList[index1].children[index2].children[
            index3
          ].children[index4],
          i
        );
      } else {
        this.form.productionProcessList[index1].children[index2].children[
          index3
        ].children.push({
          associateId: "", //标签之间的关联
          id: null,
          productionName: null,
          productionDescribe: "",
          children: [],
        });
      }
    },
    //删除四级下标签
    delet4(index1, index2, index3, index4) {
      let i =
        this.form.productionProcessList[index1].children[index2].children[
          index3
        ].children.length;
      if (i == 1) {
        this.form.groupLabelLevel = this.form.groupLabelLevel - 1;
      }
      this.form.productionProcessList[index1].children[index2].children[
        index3
      ].children.splice(index4, 1);
    },
    //五级下标签新加
    addlabel5(index1, index2, index3, index4, index5, msg, i) {
      this.Level = 1;

      if (msg != "five") {
        if (this.form.groupLabelLevel == 5) {
          this.form.groupLabelLevel = this.form.groupLabelLevel + 1;
        }
        this.levelchanges(
          this.form.productionProcessList[index1].children[index2].children[
            index3
          ].children[index4].children[index5],
          i
        );
      } else {
        this.form.productionProcessList[index1].children[index2].children[
          index3
        ].children[index4].children.push({
          associateId: "", //标签之间的关联
          id: null,
          productionName: null,
          productionDescribe: "",
          children: [],
        });
      }
    },
    //删除五级下标签
    delet5(index1, index2, index3, index4, index5) {
      let i =
        this.form.productionProcessList[index1].children[index2].children[
          index3
        ].children[index4].children.length;
      if (i == 1) {
        this.form.groupLabelLevel = this.form.groupLabelLevel - 1;
      }
      this.form.productionProcessList[index1].children[index2].children[
        index3
      ].children[index4].children.splice(index5, 1);
    },
    //六级下标签新加
    addlabel6(index1, index2, index3, index4, index5, index6, msg, i) {
      this.Level = 1;

      if (msg != "six") {
        if (this.form.groupLabelLevel == 6) {
          this.form.groupLabelLevel = this.form.groupLabelLevel + 1;
        }
        this.levelchanges(
          this.form.productionProcessList[index1].children[index2].children[
            index3
          ].children[index4].children[index5].children[index6],
          i
        );
      } else {
        this.form.productionProcessList[index1].children[index2].children[
          index3
        ].children[index4].children[index5].children.push({
          associateId: "", //标签之间的关联
          id: null,
          productionDescribe: "",
          productionName: null,
          children: [],
        });
      }
    },
    //删除6级下标签
    delet6(index1, index2, index3, index4, index5, index6) {
      let i =
        this.form.productionProcessList[index1].children[index2].children[
          index3
        ].children[index4].children[index5].children.length;
      if (i == 1) {
        this.form.groupLabelLevel = this.form.groupLabelLevel - 1;
      }
      this.form.productionProcessList[index1].children[index2].children[
        index3
      ].children[index4].children[index5].children.splice(index6, 1);
    },
    levelchanges(rlist, levelnum) {
      this.Level++;
      for (var i = 0; i < 1; i++) {
        let children;
        if (!rlist.children) {
          rlist.children = [];
        }
        children = rlist.children;
        children.push({
          associateId: "", //标签之间的关联
          id: null,
          productionName: null,
          productionDescribe: "",
          children: [],
        });
        if (this.Level < levelnum) {
          this.levelchanges(children[0], levelnum);
        }
      }
    },
    //七级下标签新加
    addlabel7(index1, index2, index3, index4, index5, index6, index7, msg, i) {
      this.Level = 1;

      if (msg != "seven") {
        if (this.form.groupLabelLevel == 7) {
          this.form.groupLabelLevel = this.form.groupLabelLevel + 1;
        }
        this.levelchanges(
          this.form.productionProcessList[index1].children[index2].children[
            index3
          ].children[index4].children[index5].children[index6].children[index7],
          i
        );
      } else {
        this.form.productionProcessList[index1].children[index2].children[
          index3
        ].children[index4].children[index5].children[index6].children.push({
          associateId: "", //标签之间的关联
          id: null,
          productionName: null,
          productionDescribe: "",
          children: [],
        });
      }
    },
    //删除7级下标签
    delet7(index1, index2, index3, index4, index5, index6, index7) {
      let i =
        this.form.productionProcessList[index1].children[index2].children[
          index3
        ].children[index4].children[index5].children[index6].children.length;
      if (i == 1) {
        this.form.groupLabelLevel = this.form.groupLabelLevel - 1;
      }

      this.form.productionProcessList[index1].children[index2].children[
        index3
      ].children[index4].children[index5].children[index6].children.splice(
        index7,
        1
      );
    },
    //八级下标签新加
    addlabel8(item7, item8, msg, i) {
      this.Level = 1;

      if (msg != "eight") {
        if (this.form.groupLabelLevel == 8) {
          this.form.groupLabelLevel = this.form.groupLabelLevel + 1;
        }
        this.levelchanges(item8, i);
      } else {
        item7.children.push({
          associateId: "", //标签之间的关联
          id: null,
          productionName: null,
          productionDescribe: "",
          children: [],
        });
      }
    },
    //删除8级下标签
    delet8(item7, item8, index8) {
      let i = item7.children.length;
      if (i == 1) {
        this.form.groupLabelLevel = this.form.groupLabelLevel - 1;
      }

      item7.children.splice(index8, 1);
    },
    //九级下标签新加
    addlabel9(
      index1,
      index2,
      index3,
      index4,
      index5,
      index6,
      index7,
      index8,
      index9,
      msg,
      i
    ) {
      this.Level = 1;

      if (msg != "nine") {
        if (this.form.groupLabelLevel == 9) {
          this.form.groupLabelLevel = this.form.groupLabelLevel + 1;
        }
        this.levelchanges(
          this.form.productionProcessList[index1].children[index2].children[
            index3
          ].children[index4].children[index5].children[index6].children[index7]
            .children[index8].children[index9],
          i
        );
      } else {
        this.form.productionProcessList[index1].children[index2].children[
          index3
        ].children[index4].children[index5].children[index6].children[
          index7
        ].children[index8].children.push({
          associateId: "", //标签之间的关联
          id: null,
          productionName: null,
          productionDescribe: "",
          children: [],
        });
      }
    },
    //删除9级下标签
    delet9(
      index1,
      index2,
      index3,
      index4,
      index5,
      index6,
      index7,
      index8,
      index9
    ) {
      let i =
        this.form.productionProcessList[index1].children[index2].children[
          index3
        ].children[index4].children[index5].children[index6].children[index7]
          .children[index8].children.length;
      if (i == 1) {
        this.form.groupLabelLevel = this.form.groupLabelLevel - 1;
      }

      this.form.productionProcessList[index1].children[index2].children[
        index3
      ].children[index4].children[index5].children[index6].children[
        index7
      ].children[index8].children.splice(index9, 1);
    },
    //10级下标签新加
    addlabel10(
      index1,
      index2,
      index3,
      index4,
      index5,
      index6,
      index7,
      index8,
      index9
    ) {
      this.Level = 1;
      this.form.productionProcessList[index1].children[index2].children[
        index3
      ].children[index4].children[index5].children[index6].children[
        index7
      ].children[index8].children[index9].children.push({
        associateId: "", //标签之间的关联
        id: null,
        productionName: null,
        productionDescribe: "",
        children: [],
      });
    },
    //删除9级下标签
    delet10(
      index1,
      index2,
      index3,
      index4,
      index5,
      index6,
      index7,
      index8,
      index9,
      index10
    ) {
      this.form.productionProcessList[index1].children[index2].children[
        index3
      ].children[index4].children[index5].children[index6].children[
        index7
      ].children[index8].children[index9].children.splice(index10, 1);
    },
    addpush(index) {
      if (index == 0) {
        this.qianList.push({
          name: "",
        });
      }
    },
    remove(index, i) {
      if (index == 0) {
        this.qianList.splice(i, 1);
      }
    },
    lookClick(val) {
      this.form.sceneImgUrl = val;
    },
    saveClick(val) {
      this.form.sceneImgList = val;
    },
    async remoteMethod(query) {
      const res = await selectAssociatedScenarios({ sceneName: query });
      if (res.code == 200) {
        this.sceneList = res.data;
      }
    },
    async remoteMethods(query) {
      const res = await getEmpoweringLabels({ labelName: query });
      if (res.code == 200) {
        this.sceneArr = res.data;
      }
    },
    async remoteMethodss() {
      const res = await getAllIndustrialChain();
      if (res.code == 200) {
        this.sceneArrs = res.data;
      }
    },
    async remoteMethodsss(query) {
      const res = await getProductByName({ productName: query });
      if (res.code == 200) {
        this.sceneArr1 = res.data;
        if (this.form.behaviorProductResultList) {
          this.sceneArr1 = this.sceneArr1.concat(
            this.form.behaviorProductResultList
          );
          this.sceneArr1 = Array.from(new Set( this.sceneArr1.map((obj) => obj.productCode))).map(
            (productCode) => {
              return  this.sceneArr1.find((obj) => obj.productCode === productCode);
            }
          );
        }
      }
    },
    async remoteMethodssss(query) {
      const res = await getZcByCompanyName({ companyFullName: query });
      if (res.code == 200) {
        this.sceneArr2 = res.data;
      }
    },
    async search() {
      const res = await selectById({ id: this.personData });
      if (res.code == 200) {
        // Object.assign(this.form, res.data);
        this.form = res.data;
        if (!this.form.productionProcessList) {
          this.form.productionProcessList = [
            {
              children: [
                {
                  children: [],
                  generationProcessId: 0,
                  id: 0,
                  parentId: 0,
                  productionDescribe: "",
                  productionName: "",
                },
              ],
              generationProcessId: 0,
              id: 0,
              parentId: 0,
              productionDescribe: "",
              productionName: "",
            },
          ];
        }
        if (this.form.labelList) {
          this.list = this.form.labelList.map((item, index) => {
            return item.split(",");
          });
        }
        this.form.productCodeList = [];
        if (this.form.behaviorProductResultList) {
          this.form.behaviorProductResultList.forEach((el) => {
            this.form.productCodeList.push(el.productCode);
          });
        }
        this.form.superiorBehaviorId = Number(this.form.superiorBehaviorId);
        this.form.industrialChainId = Number(this.form.industrialChainId);

        this.remoteMethod(this.form.sceneName);
        this.remoteMethodsss("");
        this.remoteMethodss(this.form.supplyChainName);
        this.remoteMethodssss(this.form.companyFullName);
      } else {
        this.$message.error("查询失败");
      }
    },
    async saveItem() {
      // console.log(this.radiolist);
      let res;
      if (!this.personData) {
        res = await insertGenerationProcess(this.form);
        if (res.code === 200) {
          this.$message.success("新增成功");
          this.$router.go(-1);
        } else {
          this.$message.error(res.msg);
        }
      } else {
        res = await updateGenerationProcess(this.form);
        if (res.code === 200) {
          this.$message.success("编辑成功");
          this.$router.go(-1);
        }
      }
    },
    cancelItem() {
      this.$router.go(-1);
    },
  },
};
</script>
    
    <style lang="less" scoped>
.el-radio {
  color: #606266;
  cursor: pointer;
  margin-right: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
}
svg {
  font-size: 14px;
}
.mainTop {
  background-color: white;
  padding: 20px 20px 0 20px;
  // margin-bottom: 20px;
}
.circle_flex {
  width: 680px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.content_vox {
  padding: 20px;
  background-color: #fff;
  overflow: hidden;
  .miaoshu {
    display: inline-block;
    width: 80px;
    font-size: 12px;
    color: #606266;
  }
  .left_miao {
    position: relative;
    left: -55px;
  }
}
.content {
  overflow: auto;
  width: 100%;
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px; /*滚动条的圆角*/
  background-color: grey; /*滚动条的背景颜色*/
}
.ess_people {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  margin: 20px 30px;
  align-content: center;

  .ess_people_list {
    width: 100%;
    margin: 4px 0;
    color: #7c7f8e;
    font-size: 12px;
  }
}
// 上传
/deep/ .el-upload--picture-card {
  width: 200px !important;
  height: 150px !important;
  line-height: 150px !important;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 200px;
  height: 150px;
  // line-height: 150px;
}
/deep/ .el-upload {
  background-color: #fff;
}
/deep/.el-input {
  width: 470px !important;
}
// /deep/.el-textarea {
//   width: 80% !important;
//   // min-height: 40px !important;
// }
/deep/ .el-icon-circle-plus-outline {
  font-size: 31px;
  vertical-align: middle;
  color: #698eef;
  cursor: pointer;
  padding-left: 10px;
}
.bottomStyle {
  /deep/ .el-form-item {
    margin-bottom: 5px;
  }
  /deep/ .el-upload--picture-card {
    width: 150px !important;
    height: 100px !important;
    line-height: 100px !important;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 150px;
    height: 100px;
    // line-height: 150px;
  }
  /deep/ .el-upload {
    background-color: #fff;
  }
}
/deep/ .el-icon-remove-outline {
  font-size: 31px;
  vertical-align: middle;
  color: red;
  cursor: pointer;
  padding-left: 10px;
}
.flexStyle {
  display: flex;
  margin: 10px 10px 10px 0;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
/deep/ .el-tag.el-tag--info {
  background: rgba(30, 159, 255, 0.2);
  color: rgb(30, 159, 255);
}
/deep/ .el-tag.el-tag--info .el-tag__close {
  color: rgb(30, 159, 255);
  background: rgba(30, 159, 255, 0.2);
}
.flex_box {
  display: flex;
}

.general_inputs {
  overflow: auto;
  padding: 20px 30px 30px 0px;
  border: 1px dashed #c4c4c4;
  border-radius: 2px;
  width: 97%;
  height: 450px;

  .el_inco {
    font-size: 20px;
    color: #146aff;
  }

  .input_flex {
    width: 380px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name {
      display: flex;
      align-items: center;
    }

    .inco_flex {
      display: flex;
      align-items: center;
    }

    span {
      padding-left: 15px;
      font-size: 18px;
      cursor: pointer;
    }

    .el_buttom {
      position: relative;
      left: -110px;
    }
  }
}

.num_input {
  display: flex;
  flex-direction: column;
}

.label_code {
  padding: 30px 0px;
}

.child_label {
  background: #f5f6f7;
  padding: 10px 15px;
  display: flex;
  align-items: baseline;
  width: 80%;
}

.store_table {
  height: 70px;
  box-sizing: border-box;
  margin: 0 72px 20px 0;
  padding-left: 10px;
  display: flex;

  .store_table_item {
    color: #4e93fb;
    margin-right: 50px;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  .active {
    color: #4e93fb;
    font-size: 20px;
    border-bottom: 2px solid #4e93fb;
    border-radius: 1px;
  }
}

.img_tips {
  position: relative;
  top: -10px;
  color: #6ba1ff;
}

.essential_information {
  h4 {
    color: #146aff;
  }
}

.label_management {
  padding: 30px 10px;

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }

  .input-with {
    width: 100%;
    overflow: hidden;
    padding: 20px 0px;

    .input-with-select {
      width: 400px;
      float: right;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }
}

.dialog-footer {
  border-top: 1px solid #f5f5f5;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
    